import {Navigate, useLocation} from "react-router-dom";
import useAuth from "../auth/useAuth";

function RequireAuth({ children }) {
  const { authed } = useAuth();
  const location = useLocation();

  return authed ? (
    children
  ) : (
    <Navigate to="/login/" replace state={{ path: location.pathname }} />
  );
}
export default RequireAuth;