import { useMediaQuery } from 'react-responsive'
import {isEmptyObject} from "./objects";
import {isEmpty} from "./strings";


export const useDevicePc = () => {
  return useMediaQuery({ query: '(min-width: 768px)' });
}

export const getElementByDevice = (isPc, targetElement = {}) => {
  if(targetElement !== null) {
    if (isPc || targetElement["spElement"] === null) {
      return targetElement;
    } else {
      return targetElement["spElement"];
    }
  } else {
    return {};
  }
}

export const convertElement = (source, target) => {
  if(isEmptyObject(target["style"])) {
    target["style"] = source["style"];
  }
  if(isEmptyObject(target["attr"])) {
    target["attr"] = source["attr"];
  }
  if(isEmpty(target["title1"])) {
    target["title1"] = source["title1"];
  }
  if(isEmpty(target["title2"])) {
    target["title2"] = source["title2"];
  }
  if(isEmpty(target["innerText"])) {
    target["innerText"] = source["innerText"];
  }


}